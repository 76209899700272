import React from 'react';
import Process from '../Process';
import ProductRow from '../ProductRow';
import { Link } from 'react-router-dom';
import '../Products.css';
import HomeIcon from '@material-ui/icons/Home';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Safety from '../Safety';

const Products = () => {
    return (
        <React.Fragment>
            <div className="products_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="banner_info">
                                <ul>
                                    <li><Link><HomeIcon className="banner_infoIcon"/>Home</Link></li>
                                    <li><Link><KeyboardArrowRightIcon className="banner_infoIcon"/>Products</Link></li>
                                </ul>
                                <h1>Our Products</h1>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ProductRow />
            <Process />
            <Safety />
        </React.Fragment>
    )
}

export default Products;
