import React, { Component } from 'react';
import '../components/HomeSlider.css';
import OwlCarousel from 'react-owl-carousel3';
import VisibilitySensor from 'react-visibility-sensor';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Button} from '@material-ui/core';

const options = {
    items: 1,
    nav: false,
    dots: true,
    loop: true,
    autoplay: true,
    autoplayHoverPause: true,
    touchDrag: false,
    mouseDrag: true,
    responsiveClass: true,
}

class HomeSlider extends Component {
    render() {
        return (
            <React.Fragment>
                <OwlCarousel 
                    id="home"
                    className="home-slides owl-theme"
                    navText={[
                        "<i class='icofont-swoosh-right'></i>",
                        "<i class='icofont-swoosh-right'></i>"
                    ]}
                    {...options}
                >
                    {this.props.slideData.map((data, index) => (
                        <div className={`main-banner ${data.klasName}`} key={index}>
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <VisibilitySensor >
                                                    {({isVisible}) => (
                                                        <div className="main-banner-text">
                                                            <h1 className={isVisible ? "animated fadeInDown slow opacityone" : ""}>
                                                                {data.headline}
                                                            </h1>
                                                            <p className={isVisible ? "animated fadeInDown slow opacityone" : ""}>
                                                                {data.subHeadline}
                                                            </p>
                                                            <Link to="/about">
                                                                <Button variant="outlined">Know More</Button>
                                                            </Link>
                                                        </div>
                                                    )}
                                                </VisibilitySensor>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </OwlCarousel>
            </React.Fragment>
        )
    }
}

HomeSlider.propTypes = {
    slideData: PropTypes.array
}

HomeSlider.defaultProps = {
    slideData: [
        {
            headline: "Engineered with serenity",
            subHeadline: "The only difference between manufacturing and production at Dromos -Serenity.",
            klasName: "item-bg-one"
        },
        {
            headline: 'Your assignment begins with us',
            subHeadline: 'It’s our mission to practice every project for a sustainable growth.',
            klasName: 'item-bg-two'
        },
        {
            headline: 'Not just perfection. Its excellence!!!',
            subHeadline: 'Years of hard work, dedication, passion is the reward of excellence',
            klasName: 'item-bg-three'
        },
        {
            headline: 'Supremacy Had been taken',
            subHeadline: 'A new era of engineering greatness over manpower and workforce solutions to diverse industrial sectors.',
            klasName: 'item-bg-four'
        }
    ]
}
export default HomeSlider;
