import React from 'react';
import '../components/Infrastructure.css';
import infra from '../images/Infrastructure.jpg';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ApartmentIcon from '@material-ui/icons/Apartment';

const Infrastructure = () => {
    return (
        <React.Fragment>
            <section className="infrastructure">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="infrastructure_pic">
                                <img src={infra} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="infrastructure_text">
                                <ApartmentIcon className="infrastructure_textIcon"/>
                                <h2>Infrastructure</h2>
                                <p>Our holistic approach to infrastructure takes potential challenges for the process from ideation to going on stream. We ensure time-bound shipment of the products through our robust infrastructure.
                                </p>
                                <p>Our infrastructure facilities are in 24*7 production and will met the business demand rapidly and efficiently.</p>
                                <ul>
                                    <li><DoubleArrowIcon className="infrastructure_listIcon"/> Dromos shafts - 4500 square feet</li>
                                    <li><DoubleArrowIcon className="infrastructure_listIcon"/> Auric – 2500 square feet</li>
                                    <li><DoubleArrowIcon className="infrastructure_listIcon"/> Precitech – 2500 square feet</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Infrastructure;
