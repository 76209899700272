import React from 'react';
import HomeSlider from '../HomeSlider';
import Whatwedo from '../Whatwedo';
import Whoweare from '../Whoweare';

const Home = () => {
    return (
        <React.Fragment>
            <HomeSlider />
            <Whoweare />
            <Whatwedo />
        </React.Fragment>
    )
}

export default Home;
