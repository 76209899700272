import React from 'react';
import AboutInfo from '../AboutInfo';
import Infrastructure from '../Infrastructure';
import ViMission from '../ViMission';
import { Link } from 'react-router-dom';
import '../About.css';
import HomeIcon from '@material-ui/icons/Home';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const About = () => {
    return (
        <React.Fragment>
            <div className="about_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="banner_info">
                                <ul>
                                    <li><Link><HomeIcon className="banner_infoIcon"/>Home</Link></li>
                                    <li><Link><KeyboardArrowRightIcon className="banner_infoIcon"/>About</Link></li>
                                </ul>
                                <h1>About Us</h1>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AboutInfo />
            <ViMission />
            <Infrastructure />
        </React.Fragment>
    )
}

export default About;
