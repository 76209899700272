import React from 'react';
import '../components/ContactInfo.css';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {Button} from '@material-ui/core';
import emailjs from 'emailjs-com';

const ContactInfo = () => {
      
    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('gmail', 'template_zuamewd', e.target, 'user_2HChEP5ja0iRMhpN5SqhA')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
      }

    return (
        <React.Fragment>
            <section className="contact_info">
                <div className="container">
                    
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <div className="contact_infoTitle">
                                <h2>“Let us do something great”</h2>
                                <p>We would like to hear from you. If you have any questions or feedback kindly fill the form, we will respond quickly.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row Row_two">
                        <div className="col-lg-5 col-12">
                                <div className="single_address">
                                    <PhoneIcon className="single_addressIcon" />
                                    <div className="single_addressInfo">
                                        <h4>Contact</h4>
                                        <p>+91 98431 14124</p>
                                                                            </div>
                                </div>
                                <div className="single_address">
                                    <MailIcon className="single_addressIcon"/>
                                    <div className="single_addressInfo">
                                        <h4>Mail</h4>
                                        <p>info@dromos.in</p>
                                    </div>
                                </div>
                                <div className="single_address">
                                    <LocationOnIcon className="single_addressIcon"/>
                                    <div className="single_addressInfo">
                                        <h4>Address</h4>
                                        <p>DROMOS SHAFTS, 69/2-A, Kuttai Thottam, Athipalyam Road, 
                                           Chinnavedampatti (PO), Coimbatore - 641 049.
                                        </p>
                                    </div>
                                </div>
                        </div>
                        <div className="col-lg-7 col-12">
                            <div className="form_wrapper">
                                <form onSubmit={sendEmail}>
                                    <label htmlFor="name">
                                        <input 
                                            type="text"
                                            placeholder="Name*"
                                            name="name"
                                            required
                                        />
                                    </label>
                                    <label htmlFor="email">
                                        <input 
                                            type="text"
                                            placeholder="Email*"
                                            name="email"
                                            required
                                        />
                                    </label>
                                    <label htmlFor="phone">
                                        <input 
                                            type="text"
                                            placeholder="Phone*"
                                            name="phone"
                                            required
                                        />
                                    </label>
                                    <label htmlFor="message">
                                        <textarea 
                                            placeholder="Your Message"
                                            name="message"
                                            required
                                        />
                                    </label>
                                    {/* <div className="form_wrapperMsg">
                                        Message Sent. Our executive will respond you shortly.
                                    </div> */}
                                    <Button 
                                        variant="outlined" 
                                        type="submit"
                                        name="submit"
                                        value="send message"
                                    >
                                        Send Message
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="map_area">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15663.10752560953!2d76.9834385!3d11.0553479!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4c4da24a5ec7d8cc!2sDromos%20Shafts!5e0!3m2!1sen!2sin!4v1601101712686!5m2!1sen!2sin" width="100%" title="dromos" height="450" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </React.Fragment>
    )
}

export default ContactInfo;
