import React from 'react';
import '../components/Safety.css';
import environment from '../images/environmental_care.jpg';

const Safety = () => {
    return (
        <React.Fragment>
            <section className="safety">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="processImage">
                                <img src={environment} alt="environment" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="processText">
                                <h2>Environmental care </h2>
                                <p>Industrialization, while important for the economic growth and development of a society, can also be harmful to the environment. At Dromos, we make our complex world sustainable for future through rain water harvesting, planting trees, recycling the waste and disposing hazardous materials as per government regulations.</p>
                            </div>
                            <div className="processText">
                                <h2>Health & Safety</h2>
                                <p>We enforce day to day health & safety practices, prevention efforts and training to create safe work conditions for the employees. A higher level of health and safety methods are practiced in our premises through Personal Protective Equipment, Head Protection, Hearing Protection, Eye and Face Protection, Hand and Foot Protection creating a successful workplace.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Safety;
