import React from 'react';
import '../components/Process.css';
import quality from '../images/quality.jpg';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const Process = () => {
    return (
        <React.Fragment>
            <section className="process">
                <div className="container">
                    <div className="row mb-30">
                        <div className="col-lg-6 col-12">
                            <div className="processText">
                                <h2>Manufacturing process</h2>
                                <p>We are a diverse engineering company offering an end-to-end solution across a broad spectrum of complicated projects through our manufacturing process.</p>
                                <p>“It’s the way we get the right things done”</p>
                                <p>Initiation . Concept . Procurement . Implementation . Delivery</p>
                            </div>
                            <div className="processText">
                                <h2>Quality Management</h2>
                                <p>Management through coordinated activities fulfilling the confidence of the quality from planning to execution will lead to smart and efficient outcome of the product.</p>
                                <p>We connect and deliver products with industries highest quality standards.</p>
                                <ul>
                                    <li><DoubleArrowIcon className="processTextIcon" /> To establish quality parameters.</li>
                                    <li><DoubleArrowIcon className="processTextIcon" /> Exact manufacturing principles and protocols.</li>
                                    <li><DoubleArrowIcon className="processTextIcon" /> Inspection and delivery management system from procurement to delivery.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="processImage">
                                <img src={quality} alt="quality" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Process;
