import React from 'react';
import '../components/ProductRow.css';
import products1 from '../images/products.jpg';
import products2 from '../images/Products_main.jpg';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const ProductRow = () => {
    return (
        <div className="productRow">
            <div className="container">
                <div className="row">
                     
                    <div className="col-lg-4 col-12">
                        <div className="products">
                            <h2>Products</h2>
                            <ul>
                                <li><DoubleArrowIcon fontSize="small" className="capicityIcon" /> Machined Components</li>
                                <li><DoubleArrowIcon fontSize="small" className="capicityIcon" />  Dies & Moulds</li>
                                <li><DoubleArrowIcon fontSize="small" className="capicityIcon" />  Plastic mould components</li>
                                <li><DoubleArrowIcon fontSize="small" className="capicityIcon" />  PDC components</li>
                                <li><DoubleArrowIcon fontSize="small" className="capicityIcon" />  Stamping components</li>
                                <li><DoubleArrowIcon fontSize="small" className="capicityIcon" />  Gravity die casting</li>
                            </ul>
                        </div>

                        <div className="special_process">
                            <h2>Special Process</h2>
                            <ul>
                                <li><DoubleArrowIcon fontSize="small" className="capicityIcon" />  Plating</li>
                                <li><DoubleArrowIcon fontSize="small" className="capicityIcon" />  Heat treatment</li>
                                <li><DoubleArrowIcon fontSize="small" className="capicityIcon" />  Brazing</li>
                                <li><DoubleArrowIcon fontSize="small" className="capicityIcon" />  Welding</li>
                            </ul>
                        </div>

                        <div className="capacity">
                                <div className="single_capacity">
                                    <h2>Machining Capacity</h2>
                                    <ul>
                                        <li><DoubleArrowIcon fontSize="small" className="capicityIcon" /> 2000 x 700 x 700 mm</li>
                                        <li><DoubleArrowIcon fontSize="small" className="capicityIcon" /> (With 4th Axis 360<sup>0</sup>)</li>
                                    </ul>
                                </div>
                                <div className="single_capacity">
                                    <h2>Turning Capacity</h2>
                                    <ul>
                                        <li><DoubleArrowIcon fontSize="small" className="capicityIcon" /> Maximum Dia 500 mm</li>
                                        <li><DoubleArrowIcon fontSize="small" className="capicityIcon" /> Maximum Length 1500 mm</li>
                                    </ul>
                                </div>
                                <div className="single_capacity">
                                    <h2>Cylindrical Grinding Capacity</h2>
                                    <ul>
                                        <li><DoubleArrowIcon fontSize="small" className="capicityIcon" /> Maximum Dia 250 mm</li>
                                        <li><DoubleArrowIcon fontSize="small" className="capicityIcon" /> Maximum Length 1200 mm</li>
                                    </ul>
                                </div>
                                <div className="single_capacity">
                                    <h2>Surface Grinding Capacity</h2>
                                    <ul>
                                        <li><DoubleArrowIcon fontSize="small" className="capicityIcon" /> 1200 x 500 x 600 mm</li>
                                    </ul>
                                </div>
                            </div>
                    </div>

                    <div className="col-lg-8 col-12">
                        <div className="productsImage">
                            <img src={products1} alt="products" />
                            <img src={products2} alt="products2" />
                        </div>
                    </div> 

                </div>   
            </div>       
        </div>
         
    )
}

export default ProductRow;


