import React from 'react';
import '../components/ViMission.css';

const ViMission = () => {
    return (
        <React.Fragment>
            <section className="vimission">
                <div className="container">
                    <div className="row">
                            <div className="col-lg-4 col-12">
                                <div className="vimission_info">
                                    <h4>Our Mission</h4>
                                    <p>To foster innovation and ensuing excellence in every projects.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className="vimission_info">
                                    <h4>Our Vision</h4>
                                    <p>To provide unbeatable cost effective solutions without compromising the quality.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className="vimission_info">
                                    <h4>Our Values</h4>
                                    <ul>
                                        <li>Exceptional client service with excellent operations</li>
                                        <li>Commitment to integrity, fairness and responsibility</li>
                                        <li>Performance driven through culture and character</li>
                                    </ul>
                                </div>
                            </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default ViMission;
