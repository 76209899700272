import React from 'react';
import '../components/Footer.css';
import {Link} from 'react-router-dom';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YoutubeIcon from '@material-ui/icons/YouTube';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';

const Footer = () => {
    return (
        <section className="footer">
            <div className="footer_top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="footer_topSingle">
                                <h2>Dromos</h2>
                                <p>Dromos established in 2010 is a leading company in the sector of motor & rotor shaft, valves & steel castings. Dromos is now ready to meet the modern requirements of the engineering sector worldwide.</p>
                                <ul>
                                    <li><Link to="/"><FacebookIcon className="fb_icon" /></Link></li>
                                    <li><Link to="/"><InstagramIcon className="insta_icon"/></Link></li>
                                    <li><Link to="/"><TwitterIcon className="twitter_icon"/></Link></li>
                                    <li><Link to="/"><YoutubeIcon className="yt_icon" /></Link></li>
                                </ul>
                            </div>
                        </div>
                       
                        <div className="col-lg-6 col-12">
                            <div className="footer_topSingle">
                                <h2>Location</h2>
                                <div className="footer_topSingleAddr">
                                    <LocationOnIcon className="footer_topSingleIcon" />
                                    <p>DROMOS SHAFTS, 69/2-A, Kuttai Thottam, Athipalyam Road, 
                                       Chinnavedampatti (PO), Coimbatore - 641 049.</p>
                                </div>
                                <div className="footer_topSingleAddr">
                                    <MailIcon className="footer_topSingleIcon"/>
                                    <p>info@dromos.in</p>
                                </div>
                                <div className="footer_topSingleAddr">
                                    <PhoneIcon className="footer_topSingleIcon"/>
                                    <p>+91 98431 14124</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer_bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer_bottomCopyright">
                                <p>&copy; 2020-23 Dromos. All rights reserved | Design by <a href="https://trumpetmedia.in/" rel="noopenner noreferrer">Trumpet Media Services</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Footer;
