import React from 'react';
import '../components/Topbar.css';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';

const Topbar = () => {
    return (
        <div className="topbar">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="topbar_left">
                            <p><PhoneIcon className="topbar_leftIcon" /> +91 98431 14124</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="topbar_right">
                            <p><MailIcon className="topbar_rightIcon" /> info@dromos.in</p>
                        </div>
                    </div>    
                </div>    
            </div>            
        </div>
    )
}

export default Topbar;
