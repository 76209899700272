import React from 'react';
import '../components/AboutInfo.css';
import aboutworking_together from '../images/About_Working.jpg';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import LabelIcon from '@material-ui/icons/Label';

const AboutInfo = () => {
    return (
        <React.Fragment>
            <section className="about_info">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-12 text-center">
                            <div className="about_infoTitle">
                                <GroupWorkIcon className="about_infoTitleIcon"/>
                                <h2>Working together successfully</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="about_infoText">
                                <span>About Us <LabelIcon /></span>
                                <h4>A journey towards engineering the future</h4>
                                <p>Dromos established in 2010 is a leading company in the sector of motor & rotor shaft, valves & steel castings. Dromos is now ready to meet the modern requirements of the engineering sector worldwide.</p>
                                <p>
                                We are a progressive, innovative professional firm, located in and expresses the industrial culture of Coimbatore, Tamilnadu known as Detroit of the south.
                                </p> 
                                <p>Our experienced professionals continually redefine the state of the art in every product and exemplify the highest levels of engineering technology, craftsmanship, and quality. Our expertise, experience, open communication, and unwavering commitment to meet schedules and budgets in every project have made us to be a forerunner for the years.
                                </p>
                                <p>
                                We constantly seek better ways to deliver our products and are expanding our scope to reach in response to the needs of our customers across the globe. Above all, our dedication to uncompromising service and maximum delivered value has earned the trust and loyalty of all we serve. 
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="about_infoPic img-fluid">
                                <img src={aboutworking_together} alt="ab" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default AboutInfo;
