import React from 'react';
import '../components/Whoweare.css';
import WhoAreWe from '../images/Who_Are_We.jpg';

const Whoweare = () => {
    return (
        <React.Fragment>
            <section className="whowe_are">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="whowe_areText">
                                <span>about</span>
                                <h2>Who We Are?</h2>
                                <p>Dromos established in 2010 is a leading company in the sector of motor & rotor shaft, valves & steel castings. Dromos is now ready to meet the modern requirements of the engineering sector worldwide.</p>
                                <p>We are a progressive, innovative professional firm, located in and expresses the industrial culture of Coimbatore, Tamilnadu known as Detroit of the south.</p>
                                <p>Our experienced professionals continually redefine the state of the art in every product and exemplify the highest levels of engineering technology, craftsmanship, and quality.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="whowe_areImage">
                                <img src={WhoAreWe} alt="whowe_are" />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
            <hr />
        </React.Fragment>
    )
}

export default Whoweare;
