import React from 'react';
import ContactInfo from '../ContactInfo';
import { Link } from 'react-router-dom';
import '../Contact.css';
import HomeIcon from '@material-ui/icons/Home';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';


const Contact = () => {
    return (
        <React.Fragment>
            <div className="contact_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="banner_info">
                                <ul>
                                    <li><Link><HomeIcon className="banner_infoIcon"/>Home</Link></li>
                                    <li><Link><KeyboardArrowRightIcon className="banner_infoIcon"/>Contact</Link></li>
                                </ul>
                                <h1>Contact</h1>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactInfo />
        </React.Fragment>
    )
}

export default Contact;
