import React from 'react';
import '../components/Whatwedo.css';
import whatwedo from '../images/whatwedo.jpg';
import CheckIcon from '@material-ui/icons/Check';

const Whatwedo = () => {
    return (
        <React.Fragment>
            <section className="whatwe_do">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12 order-lg-1 order-2">
                            <div className="whatwe_doImage">
                                <img src={whatwedo} alt="whatwedo" />
                            </div>
                            
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="whatwe_doText">
                                <span>What We Do</span>
                                <h2>What we offer for you</h2>
                                <p>Our experienced professionals continually redefine the state of the art in every product and exemplify the highest levels of engineering technology, craftsmanship, and quality. Our expertise, experience, open communication, and unwavering commitment to meet schedules and budgets in every project have made us to be a forerunner for the years. </p>
                                <ul>
                                    <li><CheckIcon className="check"/> Machined Components</li>
                                    <li><CheckIcon className="check"/> Dies & Moulds</li>
                                    <li><CheckIcon className="check"/> Plastic Mould Components</li>
                                    <li><CheckIcon className="check"/> PDC Components</li>
                                    <li><CheckIcon className="check"/> Stamping Components</li>
                                    <li><CheckIcon className="check"/> Gravity Die Casting</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </section>
        </React.Fragment>
    )
}

export default Whatwedo;
