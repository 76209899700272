import React from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Products from './components/pages/Products';
import Contact from './components/pages/Contact';
import Footer from './components/Footer';
import Topbar from './components/Topbar';
import ScrollUp from './components/ScrollUp';
import NavBar from './components/NavBar';

function App() {
  return (
    // BEM Naming Conventions
    <div className="app">
        <Router>
            <Topbar />
            <NavBar />
           <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/about" component={About} />
                <Route exact path="/products" component={Products} />
                <Route exact path="/contact" component={Contact} />
           </Switch>
           <ScrollUp />
           <Footer />
        </Router>
    </div>
  );
}

export default App;
